import React from "react";

class Footer extends React.Component {
  render() {
    return (
<footer id="footer">
              <div className="insideFooter">
                <ul className="nav">
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  <li>
                    <a href="/en/legal/contact-us/" target="_self">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="/en/legal/legal-notice/" target="_self">
                      Legal notice
                    </a>
                  </li>
                  <li>
                    <a href="/en/legal/private-policy/" target="_self">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="/en/legal/cookie-policy/" target="_self">
                      Cookie policy
                    </a>
                  </li>
                  
                </ul>
                <p>
                  © JNTL Consumer Health I (Switzerland) GmbH 2023
                  <br />
                  This page is published by JNTL Consumer Health I (Switzerland) GmbH, which is
                  solely responsible for the content.
                  <br />
                  It is intended for a European audience.
                  <br />
                  Last updated: 01/02/2023
                </p>
                <div className="clear" />
              </div>
            </footer>
    );
  }
}

export default Footer;
